<template>
  <v-form
    v-model="valid"
  >
    <v-expansion-panels
      mandatory
      flat
    >
      <v-expansion-panel
        v-for="(panel, i) in panels"
        :key="i"
      >
        <v-expansion-panel-header>{{ panel.title }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense>
            <v-col
              :cols="field.cols || '12'"
              v-for="(field, index) in panel.fields"
              :key="index"
            >
              <v-text-field
                v-if="field.type === 'text' && field.key !== 'ssn'"
                dense
                :label="field.required ? field.label + ' *' : field.label"
                :type="field.type"
                filled
                :required="field.required"
                v-model="value[field.key]"
                :prepend-icon="field.icon"
                :rules="field.rules"
              />
              <v-text-field
                v-if="field.type === 'text' && field.key === 'ssn'"
                dense
                :label="field.required ? field.label + ' *' : field.label"
                :type="field.type"
                filled
                :required="field.required"
                v-model="value[field.key]"
                :prepend-icon="field.icon"
                :error-messages="ssnExists ? $t('customerWithThatIdSSNExists') : ''"
                :loading="lookupLoading"
                :rules="field.rules"
              />
              <v-text-field
                v-if="field.type === 'email'"
                dense
                :label="field.required ? field.label + ' *' : field.label"
                :type="field.type"
                filled
                :required="field.required"
                v-model="value[field.key]"
                :prepend-icon="field.icon"
                :rules="field.rules"
              />
              <v-text-field
                v-if="field.type === 'phone'"
                dense
                :label="field.required ? field.label + ' *' : field.label"
                :type="field.type"
                filled
                :required="field.required"
                v-model="value[field.key]"
                :prepend-icon="field.icon"
                :rules="field.rules"
              />
              <v-text-field
                v-if="field.type === 'number'"
                dense
                :label="field.required ? field.label + ' *' : field.label"
                :type="field.type"
                filled
                :required="field.required"
                v-model="value[field.key]"
                :prepend-icon="field.icon"
                :rules="field.rules"
              />
              <v-checkbox
                v-if="field.type === 'checkbox'"
                dense
                :label="field.required ? field.label + ' *' : field.label"
                filled
                :required="field.required"
                v-model="value[field.key]"
                :prepend-icon="field.icon"
                :key="forceRenderKey"
                @change="checkboxChanged(field, value[field.key])"
                :rules="field.rules"
              />
              <v-select
                v-if="field.type === 'select'"
                dense
                :label="field.required ? field.label + ' *' : field.label"
                filled
                :required="field.required"
                v-model="value[field.key]"
                :items="field.options"
                item-value="key"
                item-text="label"
                :prepend-icon="field.icon"
                :rules="field.rules"
              />
              <v-autocomplete
                v-if="field.type === 'currency'"
                dense
                :label="field.required ? field.label + ' *' : field.label"
                filled
                :items="currencies"
                item-text="name"
                item-value="code"
                :required="field.required"
                v-model="value[field.key]"
                :prepend-icon="field.icon"
                no-data-text="Could not find currency"
                :rules="field.rules"
              >
                <template v-slot:item="{ item }">
                  <span>{{ item.name }} <span class="caption">({{ item.code }})</span></span>
                </template>
                <template v-slot:selection="{ item }">
                  <span>{{ item.name }} <span class="caption">({{ item.code }})</span></span>
                </template>
              </v-autocomplete>
              <v-autocomplete
                v-if="field.type === 'autocomplete'"
                dense
                :label="field.required ? field.label + ' *' : field.label"
                filled
                :items="field.options"
                item-text="label"
                item-value="key"
                :required="field.required"
                v-model="value[field.key]"
                :prepend-icon="field.icon"
                :no-data-text="$t('couldNotFindValue')"
                :rules="field.rules"
              />
              <v-autocomplete
                v-if="field.type === 'country'"
                dense
                :label="field.required ? field.label + ' *' : field.label"
                filled
                :items="countries"
                item-text="name"
                item-value="code"
                :required="field.required"
                v-model="value[field.key]"
                :prepend-icon="field.icon"
                :no-data-text="$t('couldNotFindCountry')"
                :rules="field.rules"
              >
                <template v-slot:item="{ item }">
                  <span>{{ item.name }} <span class="caption">({{ item.code }})</span></span>
                </template>
                <template v-slot:selection="{ item }">
                  <span>{{ item.name }} <span class="caption">({{ item.code }})</span></span>
                </template>
              </v-autocomplete>
              <DateSelection
                v-if="field.type === 'date'"
                :label="field.required ? field.label + ' *' : field.label"
                v-model="value[field.key]"
                dense
                filled
                :required="field.required"
                :icon="field.icon"
                :rules="field.rules"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-form>
</template>

  <script>
  import DateSelection from "@/components/common/DateSelection"

  export default {
    name: 'CustomerForm',
    components: {
      DateSelection
    },
    props: {
      dialog: {
        type: Boolean
      },
      value: {
        type: Object,
        default: () => {}
      },
      minimized: {
        type: Boolean
      },
      showSaveButton: {
        type: Boolean,
        default: false
      },
      triggerUpdate: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        processingIDLookup: false,
        startIndex: 0,
        isLoadingMore: false,
        saving: false,
        forceRenderKey: 0,
        valid: false,
        lookupLoading: false
      }
    },
    methods: {
      checkboxChanged(field, value) {
        if(field.key === 'invoiceable' && value === true) {
          this.value['deliverInvoice'] = true
          this.forceRenderKey += 1
        }
      },
      dialogChanged(dialog) {
        this.$emit('dialog', dialog)
      },
      fetchNameById(id) {
        return this.$store.dispatch('getNameBySSNFromNationalRegister', id)
      },
      updateCustomer() {
        return this.$store.dispatch('updateCustomer', this.value).then(res => {
          if(res) {
            this.$store.commit('updateActionSuccess', {
              message: this.$t('success'),
              subMessage: this.$t('successfullyUpdatedCustomerInformationForName', {customer: res.address.name_line})
            })
          } else {
            this.$store.commit('updateActionError', {
              message: this.$t('error'),
              subMessage: this.$t('somethingWentWrong')
            })
          }
        }).catch(err => {
          this.$store.commit('updateActionError', {
              message: this.$t('error'),
              subMessage: this.$t('somethingWentWrong')
            })
        }).finally(() => {
          this.$emit('finishedUpdating')
        })
      }
    },
    computed: {
      ssnExists() {
        if(this.value && this.value.ssn && Array.isArray(this.customers)) {
          if(this.value.uuid) {
            return this.customers.some(customer => customer && customer.ssn === this.value.ssn && customer.uuid !== this.value.uuid)
          }
          return this.customers.some(customer => customer && customer.ssn === this.value.ssn)
        } else {
          return false
        }
      },
      customers() {
        return this.$store.state.customers
      },
      countries() {
        let countries = this.$store.state.countries.slice()
        return countries.sort((a,b) => a.name.localeCompare(b.name))
      },
      currencies() {
        let currencies = this.$store.state.currencies.slice()
        return currencies.sort((a,b) => a.name.localeCompare(b.name))
      },
      rules() {
        return this.$store.getters.rules
      },
      panels() {
        return  [
          {
            title: this.$t('contact'),
            description: this.$t('theContactInformation'),
            icon: 'mdi-domain',
            fields: [
              {
                key: 'ssn',
                label: this.$t('id'),
                type: 'text',
                required: false,
              },
              {
                key: 'label',
                label: this.$t('label'),
                type: 'text',
                required: false,
                loading: false,
              },
              {
                key: 'name_line',
                label: this.$t('name'),
                type: 'text',
                required: true,
                rules: [this.rules.required]
              },
              {
                key: 'email',
                label: this.$t('email'),
                type: 'email',
                required: true,
                rules: [this.rules.required, this.rules.email]
              },
              {
                key: 'phone',
                label: this.$t('phone'),
                type: 'phone',
                required: false
              },
              {
                key: 'country',
                label: this.$t('country'),
                type: 'country',
                required: false,
              },
            ],

          },
          {
            title: this.$t('invoicing'),
            description: this.$t('invoiceSettingsForThisCustomer'),
            icon: 'mdi-domain',
            fields: [
            {
                key: 'invoiceable',
                label: this.$t('invoicable'),
                type: 'checkbox',
                required: false,
              },
              {
                key: 'deliverInvoice',
                label: this.$t('deliverInvoice'),
                type: 'checkbox',
                required: false
              },
              {
                key: 'deliverInvoiceToInExchange',
                label: this.$t('deliverToInExchange'),
                type: 'checkbox',
                required: false
              }
            ]
          },
          {
            title: this.$t('pricing'),
            description: this.$t('thePricingInformation'),
            icon: 'mdi-domain',
            fields: [
              {
                key: 'discount',
                label: this.$t('discount%'),
                type: 'autocomplete',
                options: [...Array(21).keys()].map(x => {
                  if(x === 0) {
                    return {
                      label: this.$t('none'),
                      key: x*5
                    }
                  } else {
                    return x*5
                  }
                })
              },
              {
                key: 'currency',
                label: this.$t('currencyCode'),
                type: 'currency'
              },
              {
                key: 'exclusive',
                label: this.$t('exclusive'),
                type: 'checkbox'
              }
            ]
          },
          {
            title: this.$t('personal'),
            description: this.$t('thePersonalInformation'),
            icon: 'mdi-domain',
            fields: [
              {
                label: this.$t('gender'),
                type: 'select',
                key: 'gender',
                icon: 'mdi-gender-male-female',
                options: [
                  {
                    key: 'male',
                    label: this.$t('male')
                  },
                  {
                    key: 'female',
                    label: this.$t('female')
                  },
                  {
                    key: 'other',
                    label: this.$t('other')
                  }
                ]
              },
              {
                label: 'Passport',
                type: 'text',
                required: false,
                key: 'passport',
                icon: 'mdi-passport'
              },
              {
                label: this.$t('passportExpiration'),
                type: 'date',
                required: false,
                key: 'passport_expiration_date',
              }
            ]
          }
        ]
      }
    },
    created() {
      if(this.minimized) {
        this.panels = this.panels.filter(panel => panel.title === 'Contact')
      }
    },
    watch: {
      'value.ssn' : function (newValue) {
        this.value.ssn = newValue.replace(/\D/g,'')
          if(this.value.ssn.length === 10) {
            this.lookupLoading = true
            this.fetchNameById(this.value.ssn).then(lookup => {
            if(lookup && lookup.success && lookup.data && lookup.data.name && lookup.data.name.length > 0) {
              this.value.name_line = lookup.data.name[0]
            }
          })
          .finally(() => {
            this.lookupLoading = false
          })
        }
      },
      valid(value) {
        this.$emit('valid', value)
      },
      triggerUpdate(value) {
        if(value) {
          this.updateCustomer()
        }
      }
    }
  }
  </script>

  <style scoped>

    .v-expansion-panel {
      border: thin solid rgba(0, 0, 0, 0.12);
      border-radius: 8px
    }

    .v-expansion-panels {
      border-radius: 8px
    }
  </style>
